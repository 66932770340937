<template>
  <div class="receive-agencies">
    <div class="search-bar">
      <el-button type="primary" size="small" class="btn" icon="el-icon-plus" @click="processItem({}, 1)"> 商户机构 </el-button>

      <span class="label">商户机构类型：</span>
      <KeySelect :val.sync="search.merchantInstitutionDictionaryId" class="select" req-key="receiveAgencies_type" />

      <span class="label">商户机构名称：</span>
      <el-input v-model="search.merchantInstitutionName" placeholder="请输入" size="small" class="input"> </el-input>

      <span class="label">创建人：</span>
      <el-input v-model="search.createUserName" placeholder="名称" size="small" class="input" autocomplete="none" />

      <span class="label">状态：</span>
      <el-select v-model="search.merchantInstitutionStatus" placeholder="请选择" size="small" class="select">
        <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value"> </el-option>
      </el-select>

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList"> 查询 </el-button>

      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch"> 重置 </el-button>
    </div>

    <el-table :data="tableData" style="width: 100%" class="table" border :header-cell-style="{ 'background-color': '#f5f7fa' }">
      <el-table-column prop="merchantInstitutionNo" label="编号"> </el-table-column>
      <el-table-column prop="merchantInstitutionName" label="商户机构名称"> </el-table-column>
      <el-table-column prop="merchantInstitutionDictionaryName" label="商户类型"> </el-table-column>

      <el-table-column label="关联商户">
        <template slot-scope="scope">
          <el-button type="text" @click="gotoPage(scope.row, 1)">
            {{ scope.row.relevancyMerchantNum || 0 }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="createUserName" label="创建人" width="100"> </el-table-column>
      <el-table-column prop="updateTime" label="最后更新时间"> </el-table-column>

      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.merchantInstitutionStatus == 1 ? 'success' : 'danger'">
            {{ scope.row.merchantInstitutionStatus == 1 ? '启用' : '停用' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="130">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.relevancyMerchantNum" type="text" size="small" @click="processItem(scope.row, 2)"> 编辑 </el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 3)">
            {{ scope.row.merchantInstitutionStatus == 1 ? '停用' : '启用' }}
          </el-button>

          <el-button v-if="!scope.row.relevancyMerchantNum" type="text" size="small" @click="processItem(scope.row, 4)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination background layout="total,prev,pager,next,sizes" :total="total" :page-sizes="[10, 50, 100, 200]" @size-change="pageSizeChange" @current-change="pageChange">
      </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false" :close-on-press-escape="false" top="15vw">
      <el-form ref="form" :model="formData" :rules="rules" label-width="120px" size="small">
        <el-form-item label="商户机构类型：" prop="merchantInstitutionDictionaryId">
          <KeySelect
            :val.sync="formData.merchantInstitutionDictionaryId"
            :label.sync="formData.merchantInstitutionDictionaryName"
            req-key="receiveAgencies_type"
            style="width: 100%"
          />
        </el-form-item>

        <el-form-item label="商户机构名称：" prop="merchantInstitutionName">
          <el-input v-model="formData.merchantInstitutionName" placeholder="请填写商户机构名称" style="width: 100%"></el-input>
        </el-form-item>
      </el-form>

      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMerchantInstitutionManageList, saveMerchantInstitutionManage, stopEnableMerchantInstitutionManage, deleteMerchantInstitutionManage } from '@/api/receiveAudit'

export default {
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        merchantInstitutionDictionaryId: '',
        merchantInstitutionName: '',
        merchantInstitutionStatus: '',
        createUserName: '',
      },
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '停用',
        },
        {
          value: 1,
          label: '启用',
        },
      ],
      dialogVisible: false,
      dialogTitle: '新增商户机构',
      formData: {
        merchantInstitutionId: '',
        merchantInstitutionName: '',
        merchantInstitutionDictionaryId: '',
        merchantInstitutionDictionaryName: '',
        merchantInstitutionStatus: 0,
      },
      rules: {
        merchantInstitutionDictionaryId: [{ required: true, message: '请选择商户机构类型', trigger: 'change' }],
        merchantInstitutionName: [{ required: true, message: '请输入商户机构名称', trigger: 'blur' }],
      },
    }
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search }

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    processItem(row, type) {
      type = Number(type)
      if (type === 1) {
        this.dialogVisible = true

        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.dialogTitle = '新增商户机构'
          Object.keys(this.formData).forEach((el) => {
            this.search[el] = ''
          })
        })

        return
      }

      if (type === 2) {
        this.dialogVisible = true

        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.dialogTitle = '编辑商户机构'
          this.formData = { ...row }
        })

        return
      }

      if (type === 3) {
        const num = row.relevancyMerchantNum || 0
        const stopTips = `该商户机构已关联${num}个商户账号, 确认停用后, 关联商户账号将一并停用`
        this.$confirm(Number(row.merchantInstitutionStatus) === 0 ? '确认是否启用?' : stopTips, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            stopEnableMerchantInstitutionManage({ data: row.merchantInstitutionId })
              .then((res) => {
                if (res) {
                  this.$message.success('操作成功')
                  this.getList()
                }
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))

        return
      }

      if (type === 4) {
        this.$confirm(`确认是否删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteMerchantInstitutionManage({ data: [row.merchantInstitutionId] })
              .then((res) => {
                if (res) {
                  this.$message.success('操作成功')
                  this.getList()
                }
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))
      }
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = ''
      })

      this.pageNumber = 1
      this.getList()
    },
    getList() {
      getMerchantInstitutionManageList(this.searchCondition)
        .then((res) => {
          const { content, total } = res || {}
          this.tableData = content || []
          this.total = Number(total || '0')
        })
        .catch((err) => console.log(err))
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getList()
    },
    searchList() {
      this.pageNumber = 1
      this.getList()
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) return

        saveMerchantInstitutionManage({ data: this.formData })
          .then((res) => {
            if (!res) return
            this.getList()
            this.$message.success('操作成功')
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.dialogVisible = false
          })
      })
    },
    gotoPage(row, type) {
      if (type === 1 && row.relevancyMerchantNum > 0) {
        this.$router.push({
          name: 'PayRules',
          query: { merchantInstitutionId: row.merchantInstitutionId },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.receive-agencies {
  background-color: white;
  padding: 20px;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 180px;
      margin-right: 10px;
    }

    .input {
      width: 200px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
    display: inline-block;
    width: 100%;
  }
}
</style>
